import { Box, Button, Container, Dialog, Stack } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import LoadingScreen from '../../components/loading-screen';
import { useRenewPlotsCountMutation } from '../store/plotApi';

type Props = {
  open: boolean;
  onClose: () => void;
  number: number;
  vintage: string;
  onRenewSelectedPlots: () => void;
  selectedPlots: { [key: string]: boolean };
};
const PlotDialogRenewConfirmation = ({
  open,
  onClose,
  vintage,
  selectedPlots,
  onRenewSelectedPlots,
}: Props) => {
  const [validate, setValidate] = useState<boolean>(false);
  const handleRenew = () => {
    onRenewSelectedPlots();
    setValidate(true);
  };
  const [plotCount, setPlotCount] = useState<number | null>(null);
  const [renewPlotsCount] = useRenewPlotsCountMutation();
  const countSelectPlots = Object.keys(selectedPlots).length;

  useEffect(() => {
    if (open) {
      setValidate(false);
      const selectedPlotsId: string[] = [];
      for (const key in selectedPlots) {
        if (selectedPlots[key]) {
          selectedPlotsId.push(key);
        }
      }

      renewPlotsCount({ plots: selectedPlotsId, vintage: vintage.toString() }).then((data) => {
        // @ts-ignore-ignore
        setPlotCount(data['data'] as number);
      });
    }
  }, [open]);

  if (plotCount === null) {
    return <LoadingScreen />;
  }
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <Container>
          {validate ? (
            <Stack spacing={8} m={8} justifyContent='center' alignItems='center'>
              <Box sx={{ fontWeight: 'bold' }}>
                {plotCount} {t('information.plot.renew')} {vintage}
              </Box>
              <Button variant='contained' onClick={onClose}>
                {t('button.close')}
              </Button>
            </Stack>
          ) : (
            <Stack spacing={8} m={8} justifyContent='center' alignItems='center'>
              <Box sx={{ fontWeight: 'bold', fontSize: 18 }}>
                {countSelectPlots} {t('information.plot.total.renew')}
              </Box>
              <Box sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                {plotCount} {t('information.plot.will.renew')} {vintage}.
              </Box>
              <Box sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                {countSelectPlots - plotCount} {t('information.plot.not.renew')} {vintage}
                {t('information.plot.not.renew.end')}
              </Box>
              <Stack direction='row' spacing={2}>
                <Button variant='contained' onClick={() => onClose()}>
                  {t('button.cancel')}
                </Button>
                <Button
                  variant='contained'
                  onClick={() => handleRenew()}
                  disabled={plotCount === 0}
                >
                  {t('button.validate')}
                </Button>
              </Stack>
            </Stack>
          )}
        </Container>
      </Dialog>
    </>
  );
};

export default PlotDialogRenewConfirmation;
